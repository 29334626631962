import React, { useEffect, useRef } from 'react';


const ScrollingText = ({ speed = 40 }) => {

    const scrollersRef = useRef([]);

    useEffect(() => {
      if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        addAnimation();
      }
  
      function addAnimation() {
        scrollersRef.current.forEach((scroller) => {
          // add data-animated="true" to every `.scroller` on the page
          scroller.setAttribute("data-animated", true);
  
          // Make an array from the elements within `.scroller-inner`
          const scrollerInner = scroller.querySelector(".scroller__inner");
          const scrollerContent = Array.from(scrollerInner.children);
  
          // For each item in the array, clone it
          // add aria-hidden to it
          // add it into the `.scroller-inner`
          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        });
      }
    }, []);
  
    const addScrollerRef = (el) => {
      if (el && !scrollersRef.current.includes(el)) {
        scrollersRef.current.push(el);
      }
    };

    const text = "• We Follow A Unique Process To Follow Our CRO List";

    return (
        <>
        <div className="scroller" ref={addScrollerRef}>
            <ul className="tag-list scroller__inner text-gray-200 font-bold" style={{ animationDuration: `${speed}s` }}>
                <li>
                    <div className='text-[5rem] md:text-[8rem]'>
                    <label className=''>{text}</label>
                    </div>
                </li>
            </ul>
            
        </div>
      
        </>
    );
};

export default ScrollingText;
