import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Navigation } from 'swiper/modules';
import videos from "../data/testimonials.json";
import '../assets/css/TestimonialsVideos.css';

const TestimonialsVideos = () => {
    const swiperRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mutedStates, setMutedStates] = useState(Array(videos.length).fill(true));

    const icon = 'https://ik.imagekit.io/gunadev/BrandFuzz/Logos/tbf_icon.png?updatedAt=1724313237718';

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;

        const handleSlideChange = () => {
            setTimeout(() => {
                // Process any necessary embeds or updates here
            }, 500); // Adding a delay before processing the embeds
        };

        if (swiperInstance) {
            swiperInstance.on('slideChange', handleSlideChange);
            swiperInstance.on('loopFix', handleSlideChange);
        }

        return () => {
            if (swiperInstance) {
                swiperInstance.off('slideChange', handleSlideChange);
                swiperInstance.off('loopFix', handleSlideChange);
            }
        };
    }, [swiperRef]);

    useEffect(() => {
        const autoplayNextSlide = () => {
            const swiperInstance = swiperRef.current.swiper;
            const slideDelay = 10000; // 10 seconds delay for each slide to give time for video playback

            if (swiperInstance) {
                const nextIndex = (currentIndex + 1) % swiperInstance.slides.length;
                setCurrentIndex(nextIndex);

                setTimeout(() => {
                    swiperInstance.slideTo(nextIndex);
                }, slideDelay);
            }
        };

        const interval = setInterval(autoplayNextSlide, 10000); // Set interval for each slide

        return () => clearInterval(interval);
    }, [currentIndex]);

    const handleVideoClick = (event, index) => {
        event.preventDefault();
        const video = event.target.closest('.video-wrapper').querySelector('video');
        const swiperInstance = swiperRef.current.swiper;

        setMutedStates(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });

        if (video.muted) {
            video.muted = false;
            swiperInstance.autoplay.stop();
        } else {
            video.muted = true;
            swiperInstance.autoplay.start();
        }
    };

    return (
        <section className='cust__container overflow-hidden bg-[#121212]'>
        <div id="testimonials" className="max-w-[85rem] py-10 lg:py-14 mx-auto"><h2 className="text-center font-semibold text-[37px] sm:text-[47px] xl:text-[55px] text-neutral-200 leading-tight py-4">Real Brands<span className='desk__space'>&nbsp;</span><br className='mobile__break' /><span className="text-gradient">Real Clients</span> </h2>
        

            <Swiper
                ref={swiperRef}
                modules={[Autoplay, Navigation]}
                spaceBetween={30}
                slidesPerView={1}
                loop={false}
                navigation
                autoplay={{
                    delay: 5000, // 10 seconds delay for each slide
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                onSlideChange={() => setCurrentIndex(swiperRef.current.swiper.realIndex)}
            >
                {videos.map((video, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex flex-col rounded-xl p-2 ">
                            <div className="video-wrapper flex-auto relative p-4 md:p-6 h-[565px] md:h-[600px]">
                                <div className="embed-container rounded-xl shadow-sm border border-white/10 shadow-sm relative">
                                <div className='absolute top-[20px] left-[20px]'>
                                    <img className='h-[1.5em]' src={icon} alt='tbf_icon' />
                                </div>
                                    <video
                                        autoPlay
                                        muted={mutedStates[index]}
                                        poster='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Thumbnails/thumbnailv2.jpg?updatedAt=1723365043454'
                                        className="w-full h-full object-cover"
                                        playsInline
                                    >
                                        <source src={video.videoLink} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        onClick={(event) => handleVideoClick(event, index)}
                                        className="absolute bottom-4 right-4 bg-white rounded-full p-2 shadow-md focus:outline-none"
                                    >
                                        {mutedStates[index] ? (
                                            <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                                <defs>
                                                    <style>{`.cls-1{fill:none;stroke:#020202;stroke-linecap:round;stroke-miterlimit:10;stroke-width:22px;}`}</style>
                                                </defs>
                                                <line className="cls-1" x1="644.38" x2="831.85" y1="393.76" y2="606.24"/>
                                                <line className="cls-1" x1="831.85" x2="644.38" y1="393.76" y2="606.24"/>
                                                <path className="cls-1" d="M231,358.6H353a0,0,0,0,1,0,0V641.4a0,0,0,0,1,0,0H231a62.81,62.81,0,0,1-62.81-62.81V421.4A62.81,62.81,0,0,1,231,358.6Z"/>
                                                <path className="cls-1" d="M579,408.64V818.16c0,3.19-4.65,5-7.83,3L425.27,699.55"/>
                                                <path className="cls-1" d="M394.53,672l-42.08-35.52v-273L571.19,178.83c3.18-2,7.83-.18,7.83,3V339.6"/>
                                            </svg>
                                        ) : (
                                            <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                                                <rect fill="none" height="256" width="256"/>
                                                <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
                                                <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" x1="192" x2="192" y1="104" y2="152"/>
                                                <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" x1="224" x2="224" y1="88" y2="168"/>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
        </section>
    );
};

export default TestimonialsVideos;
