import React, { useEffect, useRef } from "react";
import featureslist1 from "../data/featureslist1.json";
import featureslist2 from "../data/featureslist2.json";
import featureslist3 from "../data/featureslist3.json";
import featureslist4 from "../data/featureslist4.json";
import "../assets/css/features.css";

function Features() {
  const scrollersRef = useRef([]);

  useEffect(() => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollersRef.current.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  const addScrollerRef = (el) => {
    if (el && !scrollersRef.current.includes(el)) {
      scrollersRef.current.push(el);
    }
  };

  return (
    <section
      id="CRO List"
      className="w-screen relative flex flex-col items-center overlow-hidden"
    >
      <div className="absolute w-screen left-0 bottom-0 opacity-70 h-[220px] z-[99] background-white-gradient"></div>
      <div className="cust__container flex flex-wrap gap-6 md:gap-8 justify-center items-center py-8 overflow-hidden mb-[50px] h-auto">
        <div className="xl:max-w-[50%] flex flex-col justify-start items-center text-center h-full">
          <h2 className="flex flex-col text-black font-normal text-[100px] xl:text-[200px]">
            <span className="features__headline-wrapper font-bold  text-gradient ">
              86+{" "}
            </span>
            <span className="text-[0.2em] font-light">
              Outstanding Features
            </span>{" "}
          </h2>
        </div>
        <div className="xl:max-w-[50%] flex flex-col justify-between gap-6 h-full">
          <h2 className="features__subheading-wrapper text-[37px] sm:text-[47px] xl:text-[55px] text-black font-semibold text-center xl:text-left">
            Improved CRO
          </h2>
          <p className="text-black/60 text-[16px] sm:text-[18px] text-center xl:text-left">
            Embracing CRO helps eCommerce businesses convert website traffic
            into paying customers, so creating an effective CRO strategy can
            directly impact your bottom line. The more you tailor your website
            and content to your target audience, the more likely they will stay
            and buy.
          </p>
        </div>
      </div>
      <div className="scroller" ref={addScrollerRef}>
        <ul className="tag-list scroller__inner">
          {featureslist1.map((item, index) => (
            <li key={index}>
              <div className="bg-white shadow-md px-6 py-4 flex justify-center items-center rounded-md">
                <img className="h-[2em]" src={item.image} alt="decorative" />
                <label>{item.headline}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="scroller" ref={addScrollerRef} data-direction="right">
        <ul className="tag-list scroller__inner">
          {featureslist2.map((item, index) => (
            <li key={index}>
              <div className="bg-white shadow-md px-6 py-4 flex justify-center items-center rounded-md">
                <img className="h-[2em]" src={item.image} alt="decorative" />
                <label>{item.headline}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="scroller" ref={addScrollerRef}>
        <ul className="tag-list scroller__inner">
          {featureslist3.map((item, index) => (
            <li key={index}>
              <div className="bg-white shadow-md px-6 py-4 flex justify-center items-center rounded-md">
                <img className="h-[2em]" src={item.image} alt="decorative" />
                <label>{item.headline}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="scroller" ref={addScrollerRef} data-direction="right">
        <ul className="tag-list scroller__inner">
          {featureslist4.map((item, index) => (
            <li key={index}>
              <div className="bg-white shadow-md px-6 py-4 flex justify-center items-center rounded-md">
                <img className="h-[2em]" src={item.image} alt="decorative" />
                <label>{item.headline}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Features;
