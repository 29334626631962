import React, { useEffect, useRef } from "react";
import sections from "../data/sections.json";
import "../assets/css/sections.css";

function Sections() {
  const scrollersRef = useRef([]);

  useEffect(() => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollersRef.current.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  const addScrollerRef = (el) => {
    if (el && !scrollersRef.current.includes(el)) {
      scrollersRef.current.push(el);
    }
  };

  return (
    <section
     
      className="w-screen relative flex flex-col items-center overflow-hidden bg-[#f6f7fb]"
    >
      <div className="absolute w-screen left-0 bottom-0 h-[420px] z-[99] sections__background-white-gradient"></div>
      <div className="cust__container  py-8 overflow-hidden">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 justify-center items-center py-[2em] overflow-hidden">
        <div className=" flex justify-center xl:justify-start items-center text-center xl:text-left">
          <h2 className="sections__headline-wrapper text-black font-semibold text-[36px] md:text-[45px] xl:text-[55px]">
            Glimpse of our Client's Ecommerce Store!
          </h2>
        </div>
        <div className=" flex flex-col items-center justify-center gap-2 md:gap-6">
          <p className="text-black/60 text-[16px] sm:text-[18px] max-w-full md:max-w-[90%] text-center xl:text-left">
          Our comprehensive CRO strategies have been pivotal in driving success for our
clients. By leveraging data-driven insights, optimizing user experiences, and
implementing targeted interventions, we've helped them achieve remarkable
growth and exceed their goals.
          </p>
        </div>
        </div>
        {/*
        <div className="flex flex-wrap gap-[2em] sm:gap-[3em] justify-center px-[2em] font-medium pt-[3em]" >
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "07">Headers</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "09">Sliders</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "15">Shop</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "10">Banners</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "25">Content boxes</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "05">Lookbook</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "05">Collections grid</label>
          <label className="section__item text-[1em] sm:text-[1.2em] px-[.2em]" data-count = "70">Many more</label>
        </div>
        */}
      </div>
      <div>
        <ul className="scroller scrollerSection" ref={addScrollerRef}>
          <li className="tag-list scroller__inner flex">
            <div className="px-6 py-4 flex gap-[2em] justify-center items-start rounded-md">
              {sections.map((section, index) => (
                <div className="flex flex-col gap-[2em] justify-start items-center">
                  {section.section.map((item, index) => (
                    <div className="bg-white shadow-md">
                      <img className="w-[350px] sm:w-[390px]" src={item.image} alt="decorative" loading="lazy"/>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Sections;
