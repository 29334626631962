import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import FeaturedSection from "./components/FeaturedSection";
import TrustedClients from "./components/TrustedClients";
import FeaturedWork from "./components/FeaturedWork";
import TestimonialsVideos from "./components/TestimonialsVideos";
import DataTable from "./components/DataTable";
import ScrollingText from "./components/ScrollingText";
import Results from "./components/Results";
import Features from "./components/Features";
import Faq from "./components/Faq";
import AnnouncementBar from "./components/AnnouncementBar";
import Sections from "./components/Sections";
import Mobile from "./components/Mobile";
import Gifv1 from "./components/SectionGif";
import Footer from "./components/Footer";
import FixedButton from "./components/FixedButton";

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay with setTimeout
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1900); // Adjust this value as needed
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex justify-center items-center bg-black">
          <div class="pulse"></div>
        </div>
      ) : (
        // Content to render when loading is complete
        <>
          <div className="absolute z-[-4] inset-0 h-full w-full bg-white bg-[linear-gradient(to_right,#3a393914_1px,transparent_1px),linear-gradient(to_bottom,#3a393914_1px,transparent_1px)] bg-[size:300px_300px]"></div>
          <AnnouncementBar />
          <Header />
          <HeroSection />
          <TrustedClients />
          <FeaturedWork />
          <TestimonialsVideos />
          <DataTable />
          <ScrollingText />
          <Results />
          <Features />
          <Mobile />
          <Sections />
          <Gifv1 />
          <Faq />
         {/* <FeaturedSection /> */}
          <Footer />
        <FixedButton />
        </>
      )}
    </>
  );
}

export default App;
