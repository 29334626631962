import React, { useEffect, useRef, useState } from 'react';

function HeroSection({ speed = 40 }) {
  const videoSrc = "https://cdn.shopify.com/videos/c/o/v/3253a4b5b4b846a584084b991a52aa82.mp4";
  const scrollersRef = useRef([]);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollersRef.current.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  useEffect(() => {
    const autoplayVideos = () => {
        console.log('Page loaded, attempting to play videos.');

        const videos = document.querySelectorAll('video');
        videos.forEach((video, index) => {
            video.play()
                .then(() => {
                    console.log(`Video ${index + 1} started playing automatically.`);
                })
                .catch(error => {
                    console.error(`Autoplay failed for video ${index + 1}:`, error);
                });
        });
    };

    autoplayVideos();
    document.addEventListener('click', autoplayVideos, { once: true });

    return () => {
        document.removeEventListener('click', autoplayVideos);
    };
}, []);




  const addScrollerRef = (el) => {
    if (el && !scrollersRef.current.includes(el)) {
      scrollersRef.current.push(el);
    }
  };

  const text = "• Theme For Envato Power Elite + Truly Multipurpose Shopify Theme •";

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  return (
    <section
      id="HeroSection"
      className="relative bg-[#121212] cust__container h-auto xl:h-screen py-[40px] md:py-[80px] xl:py-0 overflow-hidden z-[999]"
    >
      <div className="relative py-4 md:py-8 lg:py-0 w-full h-full z-9 bg-[#121212]">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center h-full pt-4 lg:pt-0">
          <div className="w-full h-full col-span-1 flex flex-col justify-center items-center md:items-start gap-6 text-center md:text-left">
            <h2 className="font-semibold text-[37px] sm:text-[47px] xl:text-[55px] text-white leading-none w-full">
              <span className="text-gradient">5x ROAS </span> with highly
              converting websites
            </h2>
            <p className="text-white/60 text-[12px] sm:text-[17px] w-[80%] md:w-[95%]">
              Is your website just an online presence, or a powerful revenue-generating tool,
              Struggling with low Return on Ad Spend? An outdated website might be the reason.
            </p>
            <div className="flex sm:items-center px-4 sm:px-0">
              <a
                href="#footer"
                className="text-white flex gap-2 w-[14em] justify-center items-center text-sm md:text-[0.8rem] lg:text-[1rem] px-8 py-4 xl:py-6 rounded-full bgg-gradient thing"
              >
                <svg
                  className="w-8 text-white pr-1 border-r-[1px] border-white"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <path
                    d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216A136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                </svg>
                <span className='uppercase'>Book a call</span>
              </a>
            </div>
          </div>
          <div className="relative flex flex-col items-center md:items-end justify-start">
            <div className="absolute hidden top-[70%] -right-[16rem] transform -translate-x-1/2 -translate-y-1/2 skew-y-[-10deg] z-0 text-white text-2xl opacity-50 pointer-events-none">
              <div className="scroller scrollerHero" ref={addScrollerRef}>
                <ul className="tag-list scroller__inner text-gray-200 font-bold" style={{ animationDuration: `${speed}s` }}>
                  <li>
                    <div className='text-[0.8rem] md:text-[1rem] text-black'>
                      <label className=''>{text}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" w-full md:w-[350px] mt-8 lg:mt-0 bg-[#212121] rounded-xl shadow-sm z-10">
              <div className="video-wrapper w-full bg-black w-full relative rounded-md flex flex-col items-center justify-center bg-cover bg-center-center">
                <video
                  poster='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Thumbnails/thumbnail.jpg?updatedAt=1723364578191'
                  className="w-full h-auto rounded-md"
                  controlsList="nodownload nofullscreen"
                  autoPlay
                  muted={isMuted}
                  loop
                  playsInline
                  
                >
                  <source
                    src={videoSrc}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <button
                  onClick={handleMuteToggle}
                  className="absolute bottom-4 right-4 p-2 bg-white bg-opacity-75 rounded-full"
                >
                  {isMuted ? (
                    <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                    <defs>
                        <style>{`.cls-1{fill:none;stroke:#020202;stroke-linecap:round;stroke-miterlimit:10;stroke-width:22px;}`}</style>
                    </defs>
                    <line className="cls-1" x1="644.38" x2="831.85" y1="393.76" y2="606.24"/>
                    <line className="cls-1" x1="831.85" x2="644.38" y1="393.76" y2="606.24"/>
                    <path className="cls-1" d="M231,358.6H353a0,0,0,0,1,0,0V641.4a0,0,0,0,1,0,0H231a62.81,62.81,0,0,1-62.81-62.81V421.4A62.81,62.81,0,0,1,231,358.6Z"/>
                    <path className="cls-1" d="M579,408.64V818.16c0,3.19-4.65,5-7.83,3L425.27,699.55"/>
                    <path className="cls-1" d="M394.53,672l-42.08-35.52v-273L571.19,178.83c3.18-2,7.83-.18,7.83,3V339.6"/>
                </svg>
                  ) : (
                    <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
                    <rect fill="none" height="256" width="256"/>
                    <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
                    <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" x1="192" x2="192" y1="104" y2="152"/>
                    <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" x1="224" x2="224" y1="88" y2="168"/>
                </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
