import React from 'react';
import '../assets/css/sectiongif.css';

function Gifv1() {

    return (
        <section id="Gifv1" className="cust__container flex flex-col justify-center pb-20 overflow-hidden">
            <div className='grid grid-cols-1 xl:grid-cols-3'>
            <div className="w-full flex justify-center mb-10">
                <div className=" flex flex-col justify-center items-center xl:items-start gap-4 text-center xl:text-left">
                    <label className='uppercase font-medium text-[14px] sm:text-[16px]'>Grow your business with us</label>
                    <h2 className="sectiongifv1__headline font-semibold text-[36px] md:text-[47px] xl:text-[55px]">Your Brand Can Be The Next Success Story!</h2>
                    <p className="text-black/60 text-[16px] sm:text-[16px]">A high-converting Shopify website features a clean, mobile-optimized design, fast
loading speeds, and a streamlined checkout process. Compelling product pages,
clear CTAs, and trust signals further drive customer confidence and conversions.</p>
<a
                href="#footer"
                className="text-white flex gap-2 items-center text-sm md:text-[0.8rem] lg:text-[1rem] px-8 py-4 rounded-full bgg-gradient thing"
              >
                <svg
                  className="w-8 text-white pr-1 border-r-[1px] border-white"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <path
                    d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                  <path
                    d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216A136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                  />
                </svg>
                <span className='uppercase'>Book a call</span>
              </a>
                </div>
            </div>
            <div className='gifv1__content-wrapper col-span-2 relative w-full flex flex-col items-center justify-center'>
                <img className='w-full' src='https://ik.imagekit.io/gunadev/BrandFuzz/Gifs/hongo-shopify-ecommerce-optimization.gif?updatedAt=1723289999040' alt='' />
            </div>
            </div>
        </section>
    ); 
}

export default Gifv1;
