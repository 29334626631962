import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import results from "../data/results.json";

function Results() {
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const handleMouseMove = (e) => {
      cursor.style.transform = `translate3d(${e.clientX - 50}px, ${
        e.clientY - 50
      }px, 0)`;
    };

    const handleMouseEnter = () => {
      cursor.style.opacity = 1;
    };

    const handleMouseLeave = () => {
      cursor.style.opacity = 0;
    };

    const container = document.querySelector(".Results-wrapper");
    container.style.cursor = "none"; // Hide the original cursor
    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mouseenter", handleMouseEnter);
    container.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseenter", handleMouseEnter);
      container.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <section
      id="achievements"
      className="w-screen flex flex-col justify-center bg-black min-h-[90vh] md:min-h-screen pb-20 overflow-hidden"
    >
      <div className="cust__container flex justify-center mb-10">
        <div className="xl:max-w-[50%] flex flex-col justify-center items-center gap-6 text-center">
          <h2 className=" font-semibold text-[37px] sm:text-[47px] xl:text-[55px] text-neutral-200 leading-tight">
            Result that<span className="text-gradient">&nbsp;Speak </span>{" "}
          </h2>
          <p className="text-white/60 text-[10px] sm:text-[14px] max-w-[80%] md:max-w-[60%]">
            We bring the best creative team to serve your needs and consistently deliver results for our clients
          </p>
        </div>
      </div>
      <div className="relative w-full mb-16">
        <img
          className="absolute right-10 md:right-20 w-[15em] top-0"
          src="https://ik.imagekit.io/gunadev/BrandFuzz/Badges/2badge.png?updatedAt=1723898849239"
          alt=""
        />
      </div>
      <div className="Results-wrapper relative w-full flex flex-col items-center justify-center">
        <div ref={cursorRef} className="custom-cursor-white hidden md:flex">
          DRAG
        </div>

        <div className="w-[88%] results-container">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          >
            {results.map((result, index) => (
              <SwiperSlide key={index}>
                <div className="card flex flex-col items-center">
                  <img src={result.image} alt={result.title} />
                  <h4 className="text-white font-semibold">{result.title}</h4>
                  <p className="text-white/40">{result.tagline}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Results;
