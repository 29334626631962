import React, { useEffect, useRef } from 'react';
import clients from "../data/clients.json";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 
import { Autoplay } from 'swiper/modules';

function TrustedClients() {

  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const handleMouseMove = (e) => {
      cursor.style.transform = `translate3d(${e.clientX - 50}px, ${e.clientY - 50}px, 0)`;
    };

    const handleMouseEnter = () => {
      cursor.style.opacity = 1;
    };

    const handleMouseLeave = () => {
      cursor.style.opacity = 0;
    };

    const container = document.querySelector('.trusted-clients-container');
    container.style.cursor = 'none'; // Hide the original cursor
    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div className="bg-[#121212] pt-16 pb-16">
      <div className="mx-auto cust__container">
        <h2 className="text-center text-[20px] sm:text-[27px] lg:text-[37px] font-semibold leading-8 text-neutral-200 py-4">
          Trusted by
        </h2>
        <div className="trusted-clients-container hide-scrollbar mx-auto mt-4 md:mt-10 flex overflow-auto items-center gap-x-8 gap-y-10 sm:gap-x-10 lg:mx-0">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={2}
            loop = {true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          >
            {clients.map((client,index)=>(
              <SwiperSlide key={index}>
                <img
                  className="w-[104px] md:w-[158px] col-span-2 max-h-24 w-full object-contain lg:col-span-1"
                  src={client.logo}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div ref={cursorRef} className="custom-cursor hidden md:flex">DRAG</div>
        </div>
      </div>
    </div>
  );
}

export default TrustedClients;
