import React from 'react';
import CollapsibleCard from './ui/CollapsableCard'; // Adjust the path as necessary
import faqs from "../data/faqs.json";
import '../assets/css/faq.css';

function Faq() {
  return (
    <section id="FAQ" className="cust__container flex flex-col items-center justify-center px-2 md:px-10 my-10 md:my-20">
      <div className='w-full grid grid-cols-1 xl:grid-cols-2'>
      <div className="flex flex-col gap-6 text-center xl:text-left my-4 py-2">
        <h2 className="faq__headline-wrapper font-semibold text-[36px] sm:text-[47px] xl:text-[55px] text-black">Still Have<span className="text-gradient">&nbsp;Questions?</span> </h2>
        <span className='font-medium text-[14px] sm:text-[16px]'>We'll clear it for you!</span>
      </div>
      <div className=" grid grid-cols-1 gap-0 md:gap-1 justify-center items-center">
        {faqs.map((faq, index) => (
          <div key={index} className="w-full h-full flex flex-col justify-around max-h-[500px]">
            {faq.cards.map((card, cardIndex) => {
              return (
                <div key={`${index}-${cardIndex}`} className="w-full">
                  <CollapsibleCard card={card} />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      </div>
    </section>
  );
}

export default Faq;
