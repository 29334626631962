import React from 'react';

function FeaturedSection() {
    return (
        <section id="FeaturedSection" className='relative py-[50px] md:py-[70px] flex flex-col w-screen justify-center items-center '>
            <div className='flex flex-col'>
                <h2 className=''>Most premium and exclusive features</h2>
            </div>
        </section>
    );
}

export default FeaturedSection;