import React, { useState, useRef, useEffect } from 'react';

const CollapsibleCard = ({ card, index, cardIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const toggleCollapse = () => setIsOpen(!isOpen);

  const bgcolor = card.background;
  const HColor = card.Hcolor;
  const TColor = card.Tcolor;
  const cardStyle = card.style;

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  return (
    <div className="w-full rounded-md shadow-md overflow-hidden mb-2">
      <div
        className="cursor-pointer p-4 flex justify-between items-center"
        onClick={toggleCollapse}
        style={{ backgroundColor: bgcolor }}
      >
        <h2 className="text-[14px] sm:text-[16px] font-semibold" style={{ color: HColor }}>
          {card.question}
        </h2>
        <span
          className={`transform transition-transform duration-300 ease-in-out ${
            isOpen ? 'rotate-45' : 'rotate-0'
          }`}
        >
          +
        </span>
      </div>
      <div
        ref={contentRef}
        className={`transition-max-height duration-500 ease-in-out overflow-hidden`}
        style={{ maxHeight: '0px' }}
      >
        <div
          key={`${index}-${cardIndex}`}
          className={`p-4 ${cardStyle === 'column' ? 'flex-col' : 'flex'} gap-2`}
          style={{ backgroundColor: bgcolor }}
        >
          <img
            className={`${cardStyle === 'column' ? 'w-full hidden' : 'w-[40%] mr-4 hidden'}`}
            src={card.image}
            alt={card.question}
          />
          <div className="flex flex-col gap-2">
            <h2 className="text-[14px] sm:text-[16px] font-semibold hidden" style={{ color: HColor }}>
              {card.question}
            </h2>
            <p className="text-sm" style={{ color: TColor }}>
              {card.answer}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleCard;
