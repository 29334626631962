import React, { useEffect, useRef } from "react";
import announcements from "../data/announcements.json";

function AnnouncementBar({ speed = 40 }) {
  const scrollersRef = useRef([]);

  useEffect(() => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollersRef.current.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  const addScrollerRef = (el) => {
    if (el && !scrollersRef.current.includes(el)) {
      scrollersRef.current.push(el);
    }
  };

  return (
    <section className="w-screen overflow-hidden">
      <div className="scroller scrollerAnnouncement" ref={addScrollerRef}>
        <ul
          className="tag-list scroller__inner scroller__inner__announcement text-gray-200 font-bold"
          style={{ animationDuration: `${speed}s` }}
        >
          {announcements.map((item, index) => (
            <li key={index}>
              <div className="text-[0.6rem] md:text-[0.9rem] font-normal text-black flex text-left">
              <svg className="w-2" viewBox="0 0 1155 1914" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 966L792 10C797.333 4.66667 803.667 1.66667 811 1C818.333 0.333333 824.667 2.33333 830 7C838.667 14.3333 841.333 23.3333 838 34L625 738L1155 950L367 1904C362.333 1909.33 356.167 1912.33 348.5 1913C340.833 1913.67 334.333 1911.67 329 1907C320.333 1899 317.667 1890 321 1880L534 1175L0 966Z" fill="black"/>
</svg>
                <label className="w-fit">
                {item.text}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default AnnouncementBar;
