import React from 'react';
import '../assets/css/mobile.css';

function Mobile() {

    return (
        <section id="mobiles" className="cust__container flex flex-col justify-center py-[4em] sm:py-[6em] overflow-hidden">
            <div className="w-full flex justify-center  mb-10">
                <div className="max-w-[90%] xl:max-w-[50%] flex flex-col justify-center items-center gap-4 text-center">
                    <h2 className="mobile__headline font-semibold text-[36px] md:text-[47px] xl:text-[55px]">Responsive, Crisp & Seamless Experience</h2>
                    <p className="text-black/60 text-[16px] sm:text-[18px]">We aim to bring extraordinary experience to our customers, with crisp layout & seamless navigation.</p>
                </div>
            </div>
            <div className='mobile__screens-wrapper relative w-full flex flex-col items-center justify-center'>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-[2em] xl:gap-[5em] justify-center'>
                    <div className='w-full p-[8px] rounded-md bg-[#e3e4eb] backdrop-filter backdrop-blur-sm bg-opacity-10 border border-[#c9c8c8] shadow-md'>
                       {/*  <img className='w-full rounded-t-md ' src='https://ik.imagekit.io/gunadev/BrandFuzz/Mobile_Screens/Screenshot%202024-08-10%20at%2016.40.04.png?updatedAt=1723288259005' alt='' /> */}
                       <video className='w-full h-auto rounded-t-md' poster='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Thumbnails/thumbnailwhite.jpg?updatedAt=1723365493774' src='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Mobile/VID-20240822-WA0012.mp4?updatedAt=1724411685389' playsInline autoPlay loop muted ></video>
                        <div className='bg-black w-full py-[1em] flex justify-center items-center rounded-b-md'>
                           <label className='text-white'> Comfort Salt</label>
                        </div>
                    </div>
                    <div className='w-full h-auto p-[8px] rounded-md bg-[#e3e4eb] backdrop-filter backdrop-blur-sm bg-opacity-10 border border-[#c9c8c8] shadow-md'>
                        {/* <img className='w-full rounded-t-md ' src='https://ik.imagekit.io/gunadev/BrandFuzz/Mobile_Screens/productpage.jpg?updatedAt=1723289410586' alt='' /> */}
                        <video className='w-full rounded-t-md' poster='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Thumbnails/thumbnailwhite.jpg?updatedAt=1723365493774' src='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Mobile/VID-20240822-WA0026.mp4?updatedAt=1724411685381' playsInline autoPlay loop muted ></video>
                        <div className='bg-black w-full py-[1em] flex justify-center items-center rounded-b-md'>
                           <label className='text-white'> Rize</label>
                        </div>
                    </div>
                    <div className='w-full h-auto p-[8px] rounded-md bg-[#e3e4eb] backdrop-filter backdrop-blur-sm bg-opacity-10 border border-[#c9c8c8] shadow-md'>
                        {/* <img className='w-full rounded-t-md ' src='https://ik.imagekit.io/gunadev/BrandFuzz/Mobile_Screens/cart.jpg?updatedAt=1723289408854' alt='' /> */}
                        <video className='w-full rounded-t-md' poster='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Thumbnails/thumbnailwhite.jpg?updatedAt=1723365493774' src='https://ik.imagekit.io/gunadev/BrandFuzz/Videos/Mobile/VID-20240822-WA0027.mp4?updatedAt=1724411687068' playsInline autoPlay loop muted ></video>
                        <div className='bg-black w-full py-[1em] flex justify-center items-center rounded-b-md'>
                           <label className='text-white'> Doh Tak keh</label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Mobile;
