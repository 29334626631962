import { useState, useEffect } from "react";
import '../assets/css/datatable.css';

function DataTable() {
  const tableItems = [
    { feature: "Channel specific experience", app: "$200", rate: "1.2%" },
    { feature: "Fast load times", app: "$150", rate: "1.0%" },
    { feature: "Focused on storytelling and education", app: "$250", rate: "1.5%" },
    { feature: "Easily digestible information", app: "$180", rate: "0.8%" },
    { feature: "Seamless Navigation", app: "$220", rate: "1.1%" },
    { feature: "Product page optimization", app: "$230", rate: "1.4%" },
    { feature: "Product reviews & Testimonials", app: "$190", rate: "1.3%" },
    { feature: "UGC Section", app: "$210", rate: "1.0%" },
    { feature: "Pre Purchase Upsell", app: "$240", rate: "1.6%" },
    { feature: "In Cart Recommendations", app: "$195", rate: "1.2%" },
    { feature: "Smart Search", app: "$225", rate: "1.3%" },
    { feature: "Product Filters", app: "$170", rate: "1.0%" },
    { feature: "Advanced Mega Menu", app: "$260", rate: "1.4%" },
    { feature: "Sticky Add to Cart", app: "$205", rate: "1.1%" },
  ];

  const [viewAll, setViewAll] = useState(false);

  const toggleViewAll = () => {
    setViewAll(!viewAll);
  };

  return (
    <div id="process" className="max-w-screen-xl cust__container data__table mx-auto hide-scrollbar mt-10">
      <div className="w-full flex justify-center">
        <div className="max-w-[90%] md:max-w-[70%] lg:max-w-[50%] flex flex-col justify-center items-center gap-6 text-center">
          <h2 className="font-semibold text-[37px] sm:text-[47px] xl:text-[55px] text-black leading-tight">
            Everything at just
            <br />
            <span className="datatable__total text-[36px] sm:text-[47px] xl:text-[55px] font-bold text-[#555e6d]">
              <span className="text-gradient">&nbsp;₹35,000/- </span>
            </span>
          </h2>
        </div>
      </div>
      <div className="mt-12 drop-shadow-md overflow-x-auto bg-white py-8 border border-gray-100 shadow-lg rounded-md hide-scrollbar">
        <table className="w-full border-collapse table-auto text-sm md:text-md lg:text-lg text-left">
          <thead className="text-gray-600 font-medium border-b bg-gray-100">
            <tr>
              <th className="py-3 px-6 min-w-[150px] border-r-[1px] border-gray-300">
                Increased RAOS
              </th>
              <th className="py-3 px-6 min-w-[150px] border-r-[1px] border-gray-300">
                Shopify app cost
              </th>
              <th className="py-3 px-6 min-w-[150px] border-r-[1px] border-gray-300">
                Conversion rate
              </th>
              <th className="py-3 px-6 min-w-[150px]">
                TBF
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600 divide-y">
            {tableItems
              .slice(0, viewAll ? tableItems.length : 6)
              .map((item, idx) => (
                <tr key={idx} className="even:bg-gray-50 odd:bg-white">
                  <td className="px-6 py-4 border-r-[1px] border-gray-200">
                    {item.feature}
                  </td>
                  <td className="px-6 py-4 border-r-[1px] border-gray-200">
                    {item.app}
                  </td>
                  <td className="px-6 py-4 border-r-[1px] border-gray-200">
                    {item.rate}
                  </td>
                  <td className="px-6 py-4 flex items-center gap-1">
                    <svg
                      id="Icons"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#0d9c0d"
                    >
                      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm5.707,8.707-7,7a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414L10,14.586l6.293-6.293a1,1,0,0,1,1.414,1.414Z" />
                    </svg>
                    <span className="text-sm md:text-md lg:text-lg text-[#0d9c0d]">Included</span>
                  </td>
                </tr>
              ))}
            {!viewAll && tableItems.length > 6 && (
              <tr className="text-center table-view-gradient backdrop-blur">
                <td colSpan="4" className="py-8">
                  <button
                    onClick={toggleViewAll}
                    className="text-black bg-white shadow-xl px-4 py-2 rounded-full hover:underline text-center"
                  >
                    <span className="flex text-center justify-center items-center gap-2 font-bold">
                      <svg
                        className="w-8"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 10V8h-2v4H8l4 4 4-4h-3z" />
                        </g>
                      </svg>
                      View More
                    </span>
                  </button>
                </td>
              </tr>
            )}
            <tr className="bg-black text-white">
              <td className="px-6 py-8 border-r-[1px] border-gray-600 font-bold text-[18px]">
                Total
              </td>
              <td className="px-6 py-8 border-r-[1px] border-gray-600 line-through font-bold text-[18px]">
                +$2,000/year
              </td>
              <td className="px-6 py-8 border-r-[1px] border-gray-600 font-bold text-[18px]">
                +7.1%<sup>*</sup>
              </td>
              <td className="px-6 py-8 font-bold text-[18px]">
                ₹35,000/- Only<sup>*</sup>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-center my-4">
        <img className="w-[15em]" src="https://ik.imagekit.io/gunadev/BrandFuzz/Badges/1badge.png?updatedAt=1723898849161" alt="Badge" />
      </div>
    </div>
  );
}

export default DataTable;
