const FixedButton = () => {
  return (
    <div className="px-0 xl:px-[100px] fixed w-screen bottom-0 xl:bottom-[1em] z-[99]">
      <div className="w-full grid grid-cols-1 md:grid-cols-3 justify-center items-center gap-[10px] py-4 xl:rounded-[50px] bg-[#000000]">
        <div className="flex text-center justify-center md:justify-end md:col-span-2" >
          <label className="text-white text-[16px] md:text-[18px] font-semibold px-[5px]">Results Guaranteed Or Receive a 100% refund no questions asked!</label>
        </div>
        <div className="flex w-full justify-center md:justify-start sm:items-center sm:px-0">
          <a
            href="https://calendly.com/thebrandfuzz/discoverycall"
            className="text-white flex gap-2 items-center text-sm md:text-[0.8rem] lg:text-[1rem] px-4 py-3 md:px-6 md:py-4 rounded-full bgg-gradient thing"
          >
            <svg
              className="w-6 md:w-6 text-white pr-1 border-r-[1px] border-white"
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect fill="none" height="256" width="256" />
              <path
                d="M159.4,40A80.1,80.1,0,0,1,216,96.6"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="12"
              />
              <path
                d="M151.1,70.9a47.9,47.9,0,0,1,34,34"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="12"
              />
              <path
                d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216A136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="12"
              />
            </svg>
            <span className="text-[0.7em] md:text-[0.8em] uppercase">Book a call</span>
          </a>
        </div>
      </div>
      {/*#f15d47*/}
    </div>
    
  );
};

export default FixedButton;
